import _ from 'lodash'
import ContentfulClient from './client'
import ContentfulPreviewClient from './previewClient'


const contentfulClient = () => {
  return ContentfulClient.getClient()
}
const contentfulPreviewClient = () => {
  return ContentfulPreviewClient.getClient()
}


const transformEntry = (entry) => {
  const id = _.get(entry, 'sys.id')
  return {
    ...entry,
    id
  }
}

export const fetchResource = async ({ id }) => {
  if (!id) throw new Error('id required to fetch resource')
  return contentfulClient().getEntry(id)
    .then(transformEntry)
}

export const fetchResources = ({
  resourceType,
  where,
  select,
  order,
  skip,
  limit,
  include
}) => {
  const params = {
    content_type: resourceType,
    skip,
    limit,
    include,
    order,
    select,
    ...where
  }
  return contentfulClient().getEntries(params)
    .then((result) => {
      const { items, includes, sys, ...rest } = result || {}
      return {
        ...rest,
        resources: _.map(items, transformEntry)
      }
    })
}

export const fetchPreviews = async ({
  resourceType,
  where = {},
  select,
  order,
  skip,
  limit,
  include,
  entryId
}) => {
  if (entryId) {
    where['sys.id'] = entryId  
  }
  const params = {
    content_type: resourceType,
    skip,
    limit,
    include,
    order,
    select,
    ...where
  }

  const previews = await contentfulPreviewClient().getEntries(params)
    .then((result) => {
      const { items, includes, sys, ...rest } = result || {}
      const previewUrl = constructPreviewUrl(result)
      return {
        ...rest,
        previewUrl,
        previews: _.map(items, transformEntry)
      }
    })

  return previews 
}
export const fetchPreview = async ({ id }) => {
  const preview = await contentfulPreviewClient().getEntry(id)
  .then(transformEntry)

  return preview 
}

