import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledH3 = styled.h3`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: bold;
  letter-spacing: 0;
  width: 100%;

  ${media.forEach({
    values: { sm: '13', md: '16.5', lg: '20' },
    getStyle: (val) => `font-size: ${val}px;`
  })}
`

const H3 = ({ children, asComponent = 'h3', ...props }) => {
  return (
    <StyledH3 as={asComponent} {...props}>{children}</StyledH3>
  )
}

export default H3
