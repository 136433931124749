const _ = require('lodash')

// HANDLING RESOURCE/ENTRY OBJECT

const getFields = (resource) => {
  if (_.isArray(resource)) {
    return _.map(resource, r => {
      return _.get(r, 'fields') || {}
    })
  }

  return _.get(resource, 'fields') || {}
}

const getHasFields = (resource) => {
  const path = _.isArray(resource) ? '0.fields' : 'fields'
  return !!_.get(resource, path)
}

const getHeaderImage = (resource) => {
  return getFields(resource).headerImage
}

const getUrlName = (resource) => {
  return getFields(resource).urlName
}

const getParentCategory = (resource) => {
  return getFields(resource).parentCategory
}

const getSys = (resource) => {
  return _.get(resource, 'sys') || {}
}

const getResourceId = (resource) => {
  return getSys(resource).id
}

const getResourceType = (resource) => _.get(resource, 'sys.contentType.sys.id')

const getResourceName = (resource) => {
  const resourceType = getResourceType(resource)
  const fields = getFields(resource)
  switch (resourceType) {
    case 'event':
      return fields.title || ''
    case 'news':
      return fields.title || ''
    case 'page':
      return fields.title || ''
    case 'promotion':
      return fields.title || ''
    case 'job':
      return fields.jobTitle || ''
    case 'retailUnit':
      return fields.name || ''
    default:
      return ''
  }
}

const getUrl = (resource) => {
  const resourceType = getResourceType(resource)
  const urlName = getUrlName(resource)
  const id = getResourceId(resource)
  const parentCategory = getParentCategory(resource)

  const parentCategoryLower = _.toLower(parentCategory)
  switch (resourceType) {
    case 'page':
      return `/${urlName}`
    case 'event':
      return `/events/${urlName}`
    case 'promotion':
      const categoryType = _.get(resource, 'fields.categoryType')
      return `/${convertCategoryTypeToUrl(categoryType)}/${urlName}`
    case 'job':
      return `/jobs/${id}`
    case 'retailUnitCategory':
      return `/${_.kebabCase(
        parentCategoryLower === 'eatery' ? 'dine' : parentCategoryLower
      )}?category=${id}`
    case 'retailUnit':
      return `/${_.kebabCase(
        parentCategoryLower === 'eatery' ? 'dine' : parentCategoryLower
      )}/${urlName}`
    default:
      return `/${_.kebabCase(resourceType)}/${urlName || id}`
  }
}

// HANDLING REQUEST OBJECT

const getResources = (request) => _.get(request, 'resources')

const getFirstResource = (request) => _.head(getResources(request))

const getFirstResourceFields = (request) => {
  return _.flow([getFirstResource, getFields])(request)
}

// HANDLING ASSET OBJECT

const getAssetType = (assetObject) => {
  return _.get(assetObject, 'fields.file.contentType')
}

const getAssetSrc = (assetObject, options) => {
  let url = _.get(assetObject, 'fields.file.url')
  if (url) {
    url = 'https:' + url
    const [type, subtype] = _.split(getAssetType(assetObject), '/')
    const isImage = type === 'image'
    if (isImage) {
      const { width, height, fit } = options || {}
      url += '?q=80'
      url += `&w=${width || 800}`
      if (height) url += `&h=${height}`
      if (fit) url += `&fit=${fit}`
      const isJpg = subtype === 'jpg' || subtype === 'jpeg'
      if (isJpg) url = url + '&fl=progressive'
    }
  }
  return url
}

const getAssetTitle = (assetObject) => {
  return _.get(assetObject, 'fields.title')
}

const convertCategoryTypeToUrl = (categoryType) => {
  return {
    Competition: 'competitions',
    Offer: 'offers',
    Reward: 'rewards'
  }[categoryType]
}

// OTHER STUFF

const getEventDatesRange = ({ moment, from, to, seperator = '-', localeCode = 'en-GB' }) => {
  /**
   * Format Date to 'D MMM', if the date isn't valid return null.
   * @param {string} time Value to be formatted
   * @returns {string | null}
   */
  const validOrNull = time => !!time && moment(time).isValid()
    ? moment(time).locale(localeCode).format('D MMM')
    : null

  const fromDate = validOrNull(from)
  const toDate = validOrNull(to)
  const isSameMonth = moment(from).format('M') === moment(to).format('M')
  const datetime = {
    from: moment(from).locale(localeCode).format('YYYY-MM-DD'),
    to: moment(to).locale(localeCode).format('YYYY-MM-DD')
  }
  const isSameDay = datetime.from === datetime.to

  if (fromDate && toDate) {
    const dateData = {
      from:
        isSameDay && isSameMonth
          ? null
          : isSameMonth
            ? moment(from).locale(localeCode).format('D')
            : fromDate,
      to: toDate,
      seperator: isSameDay ? null : seperator,
      datetime
    }
    return dateData
  } else {
    return null
  }
}

const getEventDatesRangeString = ({ moment, from, to, seperator = '-', localeCode }) => {
  const dateRange = getEventDatesRange({ moment, from, to, seperator, localeCode })
  if (dateRange) {
    return `${dateRange.from ? dateRange.from : ''}${
      dateRange.seperator ? ` ${dateRange.seperator} ` : ''
      }${dateRange.to}`
  } else {
    return ''
  }
}

module.exports = {
  getFields,
  getHasFields,
  getHeaderImage,
  getUrlName,
  getParentCategory,
  getSys,
  getResourceId,
  getResourceType,
  getResourceName,
  getUrl,
  getResources,
  getFirstResource,
  getFirstResourceFields,
  getAssetSrc,
  getAssetType,
  getAssetTitle,
  convertCategoryTypeToUrl,
  getEventDatesRange,
  getEventDatesRangeString
}
