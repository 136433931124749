// // please refer to https://redux-resource.js.org/ for more
// //   information about how we're using resources in redux.
import _ from 'lodash'

import getStatus from './getStatus'

export const getResourceState = (state) => _.get(state, 'resources', {})
export const getResourceTypeState = (resourceType) => (state) => _.get(getResourceState(state), resourceType, {})

export const resourceTypeSelector = (resourceType) => (state) => _.get(getResourceTypeState(resourceType)(state), 'resources', {})

export const requestsSelector = (resourceType) => (state) => _.get(getResourceTypeState(resourceType)(state), 'requests', {})

export const requestSelector = (resourceType, requestKey) => (state) => {
  const resourceTypeResources = resourceTypeSelector(resourceType)(state)
  const request = _.get(requestsSelector(resourceType)(state), requestKey || resourceType)
  return {
    ...request,
    resources: _.map(_.get(request, 'ids'), (id) => resourceTypeResources[id]),
    status: getStatus(_.get(request, 'status'))
  }
}
