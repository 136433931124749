import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledP = styled.p`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: normal;
  ${({ small }) => small
    ? media.forEach({
      values: { sm: '10', md: '12', lg: '14' },
      getStyle: (val) => `font-size: ${val}px;`
    })
    : media.forEach({
      values: { sm: '12', md: '14', lg: '16' },
      getStyle: (val) => `font-size: ${val}px;`
    })
  }
  letter-spacing: 0;
  width: 100%;
`

const P = ({ children, ...props }) => {
  return (
    <StyledP {...props}>{children}</StyledP>
  )
}

export default P
