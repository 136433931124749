import styled from 'styled-components'

import { white, black } from '../../config/theme/colors'
import media from '../../config/theme/media'

const StyledH4 = styled.h4`
  color: ${({ inverse }) => inverse ? white() : black()};
  font-weight: bold;
  letter-spacing: 0;
  width: 100%;

  ${media.forEach({
    values: { sm: '12', md: '15', lg: '18' },
    getStyle: (val) => `font-size: ${val}px;`
  })}
`

const H4 = ({ children, ...props }) => {
  return (
    <StyledH4 {...props}>{children}</StyledH4>
  )
}

export default H4
