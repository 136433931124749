import _ from 'lodash'
import styled from 'styled-components'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import { getAssetSrc, getAssetType, getAssetTitle } from '../../utils/resources'
import media from '../../config/theme/media'
import { bodyFontColor } from '../../config/theme/colors'

import WebViewLink from '../../components/WebViewLink'
import H2 from '../../components/Typography/H2'
import H3 from '../../components/Typography/H3'
import H4 from '../../components/Typography/H4'
import P from '../../components/Typography/P'

const Image = styled.img`
  max-width: 100%;
`

const Media = styled.video`
  max-width: 100%;
`

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const DangerousHtmlWrapper = styled.div`
  display: flex;
  flex: 1;
`

const ContentfulCodeWrapper = styled(DangerousHtmlWrapper)`
div {
  display:flex;
  flex:1;
  justify-content: flex-start;
  align-items: flex-start;

  & iframe {
    flex:1;
    border: 0;
    height: 35em;
    }
}  
`

const Heading2 = ({ children, id }) => <H2 id={id}>{children}</H2>
const Heading3 = ({ children, id }) => <H3 id={id}>{children}</H3>
const Heading4 = ({ children, id }) => <H4 id={id}>{children}</H4>
const Paragraph = ({ children }) => <P>{children}</P>
const Img = ({ children, alt }) => <Image src={children} alt={alt} />
const Video = ({ children, assetType }) => (
  <Media controls>
    <source src={children} type={assetType} />
    <p>Your browser doesnt support HTML5 video.</p>
  </Media>
)

const IframeComp = ({ src, id, title, ...rest }) => (
  <IframeContainer>
    <iframe
      title={title}
      width='853'
      height='480'
      src={src}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      {...rest}
    />
  </IframeContainer>
)

const stringToElementId = (value) => value.replace(/[\W_]+/g, '-').toLowerCase()

const getHyperlinkObject = (hyperlinkNode) => {
  const youtubeUriHead = 'https://www.youtube.com/watch?v='
  const youtubeEmbedHead = 'https://www.youtube.com/embed/'

  const title = _.get(hyperlinkNode, 'content.0.value', '')
  const uri = _.get(hyperlinkNode, 'data.uri')
  const externalLink = _.includes(uri, ':')
  const target = externalLink ? '_blank' : '_self'

  const isYoutube = uri.includes(youtubeUriHead)

  return isYoutube
    ? {
      title,
      type: 'iframe',
      platform: 'youtube',
      src: uri.replace(youtubeUriHead, youtubeEmbedHead)
    }
    : { title, type: 'hyperlink', src: uri, target }
}

const options = {
  renderMark: {
    [MARKS.CODE]: (content) =>
      (<ContentfulCodeWrapper>
        <DangerousHtmlWrapper dangerouslySetInnerHTML={{ __html: content }}></DangerousHtmlWrapper>
      </ContentfulCodeWrapper>)
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading2 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading3 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading4 id={stringToElementId(node.content[0].value)}>
        {children}
      </Heading4>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    'embedded-asset-block': (node, children) => {
      const [assetType] = getAssetType(node.data.target).split('/')
      if (assetType === 'video') {
        return (
          <Video assetType={getAssetType(node.data.target)}>
            {getAssetSrc(node.data.target)}
          </Video>
        )
      }
      if (assetType === 'image') {
        return (
          <Img alt={getAssetTitle(node.data.target)}>
            {getAssetSrc(node.data.target)}
          </Img>
        )
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const hyperlinkObj = getHyperlinkObject(node)
      switch (hyperlinkObj.type) {
        case 'iframe':
          return <IframeComp {...hyperlinkObj} />
        default:
          return (
            <WebViewLink url={node.data.uri} {...hyperlinkObj}>
              {children}
            </WebViewLink>
          )
      }
    }
  }
}

export default options
