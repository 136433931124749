import { createClient } from 'contentful'

class ContentfulClient {
  constructor () {
    // only create a new instance if not previously instantiated
    if (!ContentfulClient.instance) {
      this.client = null
      ContentfulClient.instance = this
    }
    return ContentfulClient.instance
  }

  setCredentials (config) {
    console.log('Contentful Space', config.contentfulSpace)
    console.log('Contentful Environment', config.contentfulEnvironment)
    const setClient = createClient({
      space: config.contentfulSpace,
      environment: config.contentfulEnvironment,
      accessToken: config.contentfulAccessToken,
      host: config.contentfulHost
    })
    this.client = setClient
  }

  getClient () {
    return this.client
  }
}

export default new ContentfulClient()
