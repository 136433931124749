import renderRichText from './renderRichText'
import { fetchResource, fetchResources, fetchPreviews, fetchPreview } from './resources'
import contentfulClient from './client'
import contentfulPreviewClient from './previewClient'


const contentfulService = {
  renderRichText,
  fetchResource,
  fetchResources,
  fetchPreviews,
  fetchPreview,
  contentfulClient,
  contentfulPreviewClient
}

export {
  renderRichText,
  fetchResource,
  fetchResources,
  fetchPreviews,
  fetchPreview,
  contentfulClient,
  contentfulPreviewClient
}

export default contentfulService
