import { createClient } from 'contentful'

class ContentfulPreviewClient {
  constructor () {
    // only create a new instance if not previously instantiated
    if (!ContentfulPreviewClient.instance) {
      this.client = null
      ContentfulPreviewClient.instance = this
    }
    return ContentfulPreviewClient.instance
  }

  setCredentials (config) {
    const setClient = createClient({
      space: config.contentfulSpace,
      environment: config.contentfulEnvironment,
      accessToken: config.contentfulPreviewAccessToken,
      host: config.contentfulPreviewHost
    })
    this.client = setClient
  }

  getClient () {
    return this.client
  }
}

export default new ContentfulPreviewClient()