import styled from 'styled-components'

import Link from './Link'

const WebViewLink = ({ children, url, fontSize, target }) => {
  return (
    <Link href={url} target={target || '_blank'} rel={'noopener noreferrer'}>
      {children}
    </Link>
  )
}

export default WebViewLink
